import { LOCALES } from "../locales";
export default {
  [LOCALES.ITALY]: {
    tiltleFPage: "Seleziona i domini e i paesi",
    filters: "Filtri",
    domains: "Ricerca domini",
    countries: "Paesi",
    france: "Francia",
    spain: "Spagna",
    italy: "Italia",
    romania: "Romania",
    portugal: "Portogallo",
    swiss: "Svizzera",
    all: "tutti",
    search: "Ricerca",
    titlePagData: "RISULTATO RICHIESTO",
    titleTab: "Elenco delle Unità di ricerca",
    columnLabs: "Acronimo di Labs",
    columnDomain: "Domini",
    nbreSearch: "Numero di ricercatori ",
    adress: "Indirizzo ",
    contact: "Contatto ",
    webSite: "vedi il loro sito",
    english: "Inglese",
    french: "Francese",
    romanian: "Rumeno",
    italian: "Italiano",
    spanish: "Spagnolo",
    portuquese: "Portoghese",
    lang: "in inglese",
    ukraine: "Ucraino",
    show_univ: "Mostra tutte le università",
    Filter: "Filtro",
  },
};
