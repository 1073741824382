import { LOCALES } from "../locales";
export default {
  [LOCALES.ENGLISH]: {
    tiltleFPage: "Select the domains and the countries",
    filters: "Filters",
    domains: "Domain search",
    countries: "Countries",
    france: "France",
    spain: "Spain",
    italy: "Italy",
    romania: "Romania",
    portugal: "Portugal",
    swiss: "Switzerland",
    all: "All",
    search: "Search",
    titlePagData: "REQUESTED RESULT",
    titleTab: "List of Research Units",
    columnLabs: "Labs acronym",
    columnDomain: "Domains",
    nbreSearch: "Number of researchers ",
    adress: "Adress ",
    contact: "Contact ",
    webSite: "see their site",
    english: "English",
    french: "French",
    romanian: "Romanian",
    italian: "Italian",
    spanish: "Spanish",
    portuquese: "Portuguese",
    lang: "in English",
    ukraine: "Ukraine",
    show_univ: "Show all the universities",
    Filter: "Filter",
  },
};
