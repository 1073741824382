import { LOCALES } from "../locales";
export default {
  [LOCALES.PORTUGAL]: {
    tiltleFPage: "Seleccionar domínios e países",
    filters: "Filtros",
    domains: "Pesquisa por domínio ",
    countries: "Países",
    france: "França",
    spain: "Espanha",
    italy: "Itália",
    romania: "Roménia",
    portugal: "Portugal",
    swiss: "Suíça",
    all: "Todos",
    search: "pesquisar",
    titlePagData: "PESQUISA DE RESULTADOS",
    titleTab: "Lista de Unidades de Pesquisa",
    columnLabs: "Acrónimo dos laboratórios",
    columnDomain: "Domínios",
    nbreSearch: "Número de investigadores ",
    adress: "Endereço ",
    contact: "Contacto ",
    webSite: "ver o seu site",
    english: "Inglês",
    french: "Francês",
    romanian: "Romeno",
    italian: "Italiano",
    spanish: "Espanhol",
    portuquese: "Português",
    lang: "em inglês",
    ukraine: "Ucrânia",
    show_univ: "Mostrar todas as universidades",
    Filter: "Filtro",
  },
};
