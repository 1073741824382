import React, { useState, useEffect } from 'react';
import "./App.css";
import Test from "./Carte";
import "@progress/kendo-theme-default/dist/all.css";
import { BrowserRouter as Router, Routes, Route , Link, useLocation }  from "react-router-dom";

import ContactForm from "./ContactUs"
//import Carte from './Carte';

import { I18nProvider, LOCALES } from "./i18n";

//import { default as  i18n } from ' i18n';
import i18next from "i18next";
import translate from "./i18n/translate";


function Navbar() {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = React.useState('/');

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);  

  return (
    <nav>
      <ul style={{ listStyleType: 'none', display: 'flex', flexDirection: 'row', padding: '20px' }}> 
        <li>
     <a href="/"  className={`nav-link ${activeRoute === '/' ? 'activenavBar' : ''}`}  >Home</a> 
        </li>
        <li>
          <a href="/ContactForm" className={`nav-link ${activeRoute === '/ContactForm' ? 'activenavBar' : ''}`}  >Contact us</a>
        </li>
      </ul>
    </nav>
  );
}

function App() {
  const [selected, setSelected] = useState();
  const [locale, setLocale] = useState(LOCALES.ENGLISH);

  const handleChange = (event) => {
    const language = event.target.value;
    switch (language) {
      case LOCALES.ENGLISH:
        setLocale(LOCALES.ENGLISH);
        i18next.handleChange(LOCALES.ENGLISH);
        setSelected(event.target.value);
        break;
      case LOCALES.FRENCH:
        setLocale(LOCALES.FRENCH);
        i18next.handleChange(LOCALES.FRENCH);
        setSelected(event.target.value);
        break;

      case LOCALES.ROMANIA:
        setLocale(LOCALES.ROMANIA);
        i18next.handleChange(LOCALES.ROMANIA);
        setSelected(event.target.value);
        break;

      case LOCALES.ITALY:
        setLocale(LOCALES.ITALY);
        i18next.handleChange(LOCALES.ITALY);
        setSelected(event.target.value);
        break;

      case LOCALES.PORTUGAL:
        setLocale(LOCALES.PORTUGAL);
        i18next.handleChange(LOCALES.PORTUGAL);
        setSelected(event.target.value);
        break;

      case LOCALES.SPAIN:
      default:
        setLocale(LOCALES.SPAIN);
        i18next.handleChange(LOCALES.SPAIN);
        setSelected(event.target.value);
        break;
    }
  };

  return (
    <I18nProvider locale={locale}>
      <section>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css"
          integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor"
          crossorigin="anonymous"
        />
        <div class="container-fluid bg-green">
          <div class="row ps-4 d-flex align-items-center ">
            <div class="col-6 col-md-1 one">
            <a class="navbar-brand p-2 " href="/">
                <img src="logounitaResearchunits.png" width="90" height="90" alt="" />
              </a>
          
            </div>
            <div class="col-8 col-md-3 two d-flex justify-content-start">
            <Router>
              <Navbar/>
            </Router>
 
            
       
            </div>
            <div class="col-4 col-md-6  three d-flex justify-content-end">
              <form>
                <label>
                  <select
                    className="form-select form-select-sm"
                    value={selected}
                    onChange={handleChange}
                  >
                    <option value={LOCALES.ENGLISH}>
                      {translate("english")}
                    </option>
                    <option value={LOCALES.FRENCH}>
                      {translate("french")}
                    </option>
                    <option value={LOCALES.SPAIN}>
                      {translate("spanish")}
                    </option>
                    <option value={LOCALES.ROMANIA}>
                      {translate("romanian")}
                    </option>
                    <option value={LOCALES.ITALY}>
                      {translate("italian")}
                    </option>
                    <option value={LOCALES.PORTUGAL}>
                      {translate("portuquese")}
                    </option>
                  </select>
                </label>
              </form>
              
            </div>
            <div class="col-6 col-md-1 m-auto four">
    
              <a class="navbar-brand p-2 " href="https://univ-unita.eu/">
                <img src="logounita.jpg" width="90" height="90" alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Router>
        <Routes>
          <Route exact path="/" element={<Test />} />
          <Route exact path="/ContactForm" element={<ContactForm />} />
          
        </Routes>
    
      </Router>
    </I18nProvider>
  );
}

export default App;
