import { Helmet, HelmetProvider } from "react-helmet-async";
import React from "react";
import "./App.css";
import "leaflet/dist/leaflet.css";

//import ReactDOM from 'react-dom';
import RecupUNIV from "./donnees";
//import Filter from './filter';
//import translate from './i18n/translate';
class Test extends React.Component {
  render() {
    return (
      <div className="row p-4">
        <br></br>
        {/* <nav class="w-full bg-white rounded-lg p-1 pr-6 flex justify-between items-center">
          <div className="container row">
            {/* <HelmetProvider>
            {" "}
            <div>
              <Helmet
                bodyAttributes={{ style: "background-color : #F0F9FD" }}
              />
            </div>
          </HelmetProvider> 
            <div className="col-md-6">
              <img src="logounita.jpg" className="img-fluid" alt=""></img>
            </div>
          </div>
        </nav>*/}
        <RecupUNIV />
      </div>
    );
  }
}

export default Test;
