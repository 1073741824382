import { LOCALES } from "../locales";
export default {
  [LOCALES.FRENCH]: {
    tiltleFPage: "Sélectionnez les domaines et les pays",
    filters: "Filtres",
    domains: "Recherche par domaine ",
    countries: "Pays",
    france: "France",
    spain: "Espagne",
    italy: "Italie",
    romania: "Roumanie",
    portugal: "Portugal",
    swiss: "Suisse",
    all: "Tous ",
    search: "Recherche",
    titlePagData: "RÉSULTAT ",
    titleTab: "Liste des Unités de Recherche",
    columnLabs: "Acronyme des laboratoires",
    columnDomain: "Domaines",
    nbreSearch: "Nombre de chercheurs ",
    adress: "Adresse ",
    contact: "Contact ",
    webSite: "voir leur site",
    english: "Anglais",
    french: "Francais",
    romanian: "Roumain ",
    italian: "Italien",
    spanish: "Espagnol",
    portuquese: "Portugais",
    lang: "en anglais",
    ukraine: "Ukraine",
    show_univ: "Afficher toutes les universités",
    Filter: "Filtrer",
  },
};
