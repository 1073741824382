import en from './en-US';
import fr from './fr-FR';
import po from './po-PO';
import es from './es-ES';
import it from './it-IT';
import ro from './ro-RO'

export default {
    ...en,
    ...fr,
    ...ro,
    ...it,
    ...es,
    ...po,
}