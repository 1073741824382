import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from "react";
import "./ContactUs.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm();
  const [captcha, setCaptcha] = useState(null);

  const handleCaptchaChange = (value) => {
    // console.log("Captcha value:", value);
    setCaptcha(value);
  };
  const captchaRef = useRef(null);
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // const verifyCaptcha = async (response) => {
  //   console.log('Captcha response:', response);
  //   const verifier = new RecaptchaVerifier({
  //     secretKey: '6Lec79kkAAAAAJHRqB7JBCv0ll1vST0eA3wKMtze',
  //   });
  //   return await verifier.verify(response);
  // };
  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast(
      "Thank you for getting in touch! We appreciate your message and will get back to you as soon as possible.",
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: "submit-feedback success",
        toastId: "notifyToast",
      }
    );
  };
  const api = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    // const { name, email, subject, message } = data;
    // try {
    //   const templateParams = {
    //     name,
    //     email,
    //     subject,
    //     message
    //   };

    //   reset();
    //   toastifySuccess();
    // } catch (e) {
    //   console.log(e);
    // }

    if (!captcha) {
      toast.error("Please complete the CAPTCHA");
      return;
    }

    // try {
    //   await verifyCaptcha(captcha);
    // } catch (error) {
    //   toast.error('Invalid CAPTCHA');
    //   return;
    // }
    const { name, email, subject, message } = data;
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("message", message);

      // Send form data to backend API endpoint
      const response = await api.post("/api/submit-form", data, config);

      if (response.status === 200) {
        reset();
        captchaRef.current.reset();
        toastifySuccess();
      } else {
        // Handle errors if any
        // console.log(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div class="form-body">
      <div class="row">
        <div class="form-holder">
          <div class="form-content">
            <div class="form-items">
              <h3>Keep in touch</h3>
              <p>
                We'd love to hear from you! If you have any suggestions,
                feedback, please feel free to send us a message.
              </p>
              <form
                id="contact-form"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <div class="col-md-6">
                  <input
                    type="text"
                    name="name"
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Please enter your name",
                      },
                      maxLength: {
                        value: 30,
                        message: "Please use 30 characters or less",
                      },
                    })}
                    className="form-control formInput"
                    placeholder="Name"
                  ></input>
                  {errors.name && (
                    <span className="errorMessage">{errors.name.message}</span>
                  )}
                </div>

                <div class="col-md-6 ">
                  <input
                    type="email"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                    className="form-control formInput"
                    placeholder="Email address"
                  ></input>
                  {errors.email && (
                    <span className="errorMessage">
                      Please enter a valid email address
                    </span>
                  )}
                </div>

                <div class="col-md-12">
                  <input
                    type="text"
                    name="subject"
                    {...register("subject", {
                      required: {
                        value: true,
                        message: "Please enter a subject",
                      },
                      maxLength: {
                        value: 75,
                        message: "Subject cannot exceed 75 characters",
                      },
                    })}
                    className="form-control formInput"
                    placeholder="Subject"
                  ></input>
                  {errors.subject && (
                    <span className="errorMessage">
                      {errors.subject.message}
                    </span>
                  )}
                </div>

                <div class="col-md-12">
                  <textarea
                    rows={3}
                    name="message"
                    {...register("message", {
                      required: true,
                    })}
                    className="form-control formInput"
                    placeholder="Message"
                  ></textarea>
                  {errors.message && (
                    <span className="errorMessage">Please enter a message</span>
                  )}
                </div>
                <div class="p-3">
                  <ReCAPTCHA
                    sitekey="6Lec79kkAAAAANM0BjRagT3ikRd2B3UDNwcVcj7H"
                    ref={captchaRef}
                    onChange={handleCaptchaChange}
                  />
                </div>

                <div class=" col-md-12 form-button mt-3">
                  <button id="submit" type="submit" class="btn btn-primary">
                    Send
                  </button>
                </div>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
