import { LOCALES } from "../locales";
export default {
  [LOCALES.ROMANIA]: {
    tiltleFPage: "Selectați domeniile și țările",
    filters: "Filtru",
    domains: "Căutare după domeniu",
    countries: "Țări",
    france: "Franța",
    spain: "Spania",
    italy: "Italia",
    romania: "România",
    portugal: "Portugalia",
    swiss: "Elveţia",
    all: "Toate selectate",
    search: "Căutare",
    titlePagData: "REZULTAT CERCETAT",
    titleTab: "Lista unităților de cercetare",
    columnLabs: "Acronimul laboratoarelor",
    columnDomain: "Domenii",
    nbreSearch: "Numărul de cercetători ",
    adress: "Adresa ",
    contact: "Contactați ",
    webSite: "vezi site-ul lor",
    english: "Engleză",
    french: "Franceză",
    romanian: "Românesc",
    italian: "Italian",
    spanish: "Spaniolă",
    portuquese: "Portugheză",
    lang: "în engleză",
    ukraine: "Ucraina",
    show_univ: "Arată toate universitățile",
    Filter: "Filtru",
  },
};
