import React, { useCallback, useRef } from "react";
import * as ReactDOM from "react-dom";
import "./App.css";
// import { AutoComplete } from "@progress/kendo-react-dropdowns";
import "./style.css";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import Axios from "axios";
import { useState, useEffect } from "react";
import translate from "./i18n/translate";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Card, Button } from "react-bootstrap";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

const RecupUNIV = () => {
  // Get universities
  const api = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
  const [datas, setDatas] = useState([]);
  const [labs, setLabs] = useState([]); //GET laboratories
  const [rdomains, setrdomains] = useState([]);
  const [tags, setTags] = useState([]);

  // Cette fonction sera appelée à chaque fois que les tags changent
  const handleTagsChange = (newTags, e) => {
    setTags(newTags); // Mettre à jour l'état des tags

    // Vérifie si la touche "Entrée" a été pressée lors de l'ajout d'un tag
    if (e && e.keyCode === 13) {
    }
    resetMarkers(markerselected);
    
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleDelete = (index) => {
    const newTags = tags.slice(0);
    newTags.splice(index, 1);
    setTags(newTags);
  };

  useEffect(() => {
    const getToken = async () => {
      const response = await api.get("/api/generateToken");
      const token = response.data;
      localStorage.setItem("accessToken", token);
      return token;
    };

    const getUniversities = async () => {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get("/api/get/univ", config);
      setDatas(response.data);
      // console.log(response.data);
    };
    const getLaboraties = async () => {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get("/api/get/labos", config);
      setLabs(response.data);
      // console.log(response.data);
    };
    const getAssociations = async () => {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get("/api/get/asso", config);
      setrdomains(response.data);
      // console.log(response.data);
    };
    const fetchData = async () => {
      try {
        const token = await getToken();
        const universities = await getUniversities();
        const laboratories = await getLaboraties();
        const associations = await getAssociations();

        // console.log("universities  " + universities);
        // console.log("laboratories  " + laboratories);
        // console.log("associations  " + associations);
        // You can also set the universities data to a state variable here
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  /*---------------------------------------->Customize the icons<---------------------------------*/
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });
  var IconC = L.divIcon({
    className: "custom-div-icon",
    html: "<div style='background-color:#c30b82;' class='marker-pin'></div><i style='color:#0C2340;' class='material-icons'>school</i>",
    iconSize: [30, 40],
    iconAnchor: [15, 42],
    popupAnchor: [0, -25],
  });
  var IconL = L.divIcon({
    className: "custom-div-icon",
    html: "<div style='background-color:#4838cc;' class='marker-pin'></div><i style='color:#0C2340;' class='material-icons'>biotech</i>",
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -25],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  /*France*/
  const [checked, setChecked] = useState(true);
  const handleChangeMessage = () => {
    setMessage(!checked);
  };
  const handleChange = () => {
    setChecked(!checked);
    setshowMe(true);
    deleteMarkers();
    setshowuniv(true);
    setCheckeduniv(true);
  };
  /*Spain*/
  const [checked1, setChecked1] = useState(true);
  const handleChange1 = () => {
    setChecked1(!checked1);
    setshowMe(true);
    deleteMarkers();
    setshowuniv(true);
    setCheckeduniv(true);
  };
  const [showuniv, setshowuniv] = useState(true);
  const [checkeduniv, setCheckeduniv] = useState(true);
  const handleChangeuniv = () => {
    setCheckeduniv(!checkeduniv);
    if (!checkeduniv) {
      setshowuniv(true);
    } else {
      setshowuniv(false);
    }
  };

  /*Italy*/
  const [checked2, setChecked2] = useState(true);
  const handleChange2 = () => {
    setChecked2(!checked2);
    setshowMe(true);
    deleteMarkers();
    setshowuniv(true);
    setCheckeduniv(true);
  };
  /*Romania*/
  const [checked3, setChecked3] = useState(true);
  const handleChange3 = () => {
    setChecked3(!checked3);
    setshowMe(true);
    deleteMarkers();
    setshowuniv(true);
    setCheckeduniv(true);
  };
  /*Portigal*/
  const [checked4, setChecked4] = useState(true);
  const handleChange4 = () => {
    setChecked4(!checked4);
    setshowMe(true);
    deleteMarkers();
    setshowuniv(true);
    setCheckeduniv(true);
  };
  /* Ukraine */
  const [checked6, setChecked6] = useState(true);
  const handleChange6 = () => {
    setChecked6(!checked6);
    setshowMe(true);
    deleteMarkers();
    setshowuniv(true);
    setCheckeduniv(true);
  };

  /* Swiss */
  const [checked7, setChecked7] = useState(true);
  const handleChange7 = () => {
    setChecked7(!checked7);
    setshowMe(true);

    setshowuniv(true);
    setCheckeduniv(true);
    deleteMarkers();
  };
  /*All*/
  const [checked5, setChecked5] = useState(true);
  const handleChange5 = () => {
    setChecked5(!checked5);
    setshowMe(true);
    deleteMarkers();
    if (!checked5) {
      setChecked({ checked: true });

      setChecked1({ checked1: true });
      setChecked2({ checked2: true });
      setChecked3({ checked3: true });
      setChecked4({ checked4: true });
      setChecked6({ checked6: true });
      setChecked7({ checked7: true });
    } else {
      setChecked(!checked);

      setshowuniv(true);
      setCheckeduniv(true);
      setChecked1(!checked1);
      setChecked2(!checked2);
      setChecked3(!checked3);
      setChecked4(!checked4);
      setChecked6(!checked6);
      setChecked7(!checked7);
    }

    //;
  };

  const [showMe, setshowMe] = useState(true);
  const [message, setMessage] = useState(false);
  const [sTerm, setsTerm] = useState();

  const handleSTerm = (e) => {
    let value = e.target.value;
    setsTerm(value);
    // setshowMe(true); resetMarkers(markerselected);
    handleChangeMessage();
  };
  //console.log(sTerm);
  const rechercher = [];
  const uniq = [];
  for (var i = 0; i < rdomains.length; i++) {
    if (rdomains[i].field === sTerm) {
      rechercher.push(i);

      uniq.push(rdomains[i].labs);
    }
  }

  if (Object.keys(tags).length > 0) {
    const stringifiedTags = JSON.stringify(tags);
    const tagsArray = JSON.parse(stringifiedTags); // Convert stringifiedTags back to an array
    for (let j = 0; j < rdomains.length; j++) {
      for (let i = 0; i < tagsArray.length; i++) {
        if (
          rdomains[j].field.toLowerCase().includes(tagsArray[i].toLowerCase())
        ) {
          uniq.push(rdomains[j].labs.toLowerCase());
        }
      }
    }
  }

  var nLabs = [];
  for (i = 0; i < labs.length; i++) {
    for (var j = 0; j < uniq.length; j++) {
      if (labs[i].acronymelabs.toLowerCase() === uniq[j].toLowerCase()) {
        if (nLabs.indexOf(labs[i]) > -1) {
          continue;
        } else {
          nLabs.push(labs[i]);
          continue;
        }
      }
    }
  }

  for (i = 0; i < labs.length; i++) {
    const jsontags = tags;
    var keywordsArrEn = labs[i].keywords_en
      ? labs[i].keywords_en.split(",")
      : [];
    var keywordsArrFr = labs[i].keywords_fr
      ? labs[i].keywords_fr.split(",")
      : [];
    var keywordsArrEs = labs[i].keywords_es
      ? labs[i].keywords_es.split(",")
      : [];
    var keywordsArr = [...keywordsArrEn, ...keywordsArrFr, ...keywordsArrEs];

    if (Object.keys(jsontags).length > 0 && keywordsArr.length > 0) {
      const included = keywordsArr.some((keyword) => {
        const stringifiedTags = JSON.stringify(jsontags);
        const trimmedKeyword = keyword.trim();
        let keywordFound = false;
        const tagsArray = JSON.parse(stringifiedTags); // Convert stringifiedTags back to an array
        for (let j = 0; j < tagsArray.length; j++) {
          if (
            trimmedKeyword.toLowerCase().includes(tagsArray[j].toLowerCase()) ||
            labs[i].acronymelabs
              .toLowerCase()
              .includes(tagsArray[j].toLowerCase())
          ) {
            keywordFound = true;
            break;
          }
        }
        return keywordFound;
      });
      if (included) {
        nLabs.push(labs[i]);
      }
    }
  }

  const dd = [];
  nLabs = [...new Set(nLabs)];

  // console.log(nLabs);
  for (i = 0; i < rdomains.length; i++) {
    for (j = 0; j < nLabs.length; j++) {
      if (rdomains[i].labs === nLabs[j].acronymelabs) {
        dd.push(rdomains[i]);
      }
    }
  }

  const [value, setValue] = useState([]);
  const onChange = (event: MultiSelectChangeEvent) => {
    setValue([...event.value]);
  };

  const sortedfield = [];
  for (i = 0; i < rdomains.length; i++) {
    sortedfield.push(rdomains[i].field);
  }
  const array = sortedfield;
  const sortedArray = [...new Set(array)].sort();

  const source = sortedArray;
  const [state, setState] = React.useState({ data: source, value: "" });

  const filterData = (value: string) => {
    const data = source;
    const filter: FilterDescriptor = {
      value: value.toString(),
      operator: "startswith",
      ignoreCase: true,
    };
    return value ? filterBy(data, filter) : data;
  };

  const onChange2 = (event: AutoCompleteChangeEvent) => {
    const value = event.value.toString();
    setsTerm(value);
    setshowMe(true);
    deleteMarkers();
    handleChangeMessage();

    setState({
      data: filterData(value),
      value: value,
    });
  };

  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const zoom = 13;
  function onClick222(center, name) {
    mapRef.current.setView(center, zoom);
  }

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    // Initialize Leaflet.markercluster plugin
    const markerClusterGroup = L.markerClusterGroup({
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: true,
      maxClusterRadius: 25, // en mètres
      // Set the zoom delta
      zoomDelta: 2.5,
    });

    // Add markers to the group
    markers.forEach((marker) => {
      markerClusterGroup.addLayer(marker);
    });

    // Add the group to the map
    if (mapRef.current !== null) {
      mapRef.current.addLayer(markerClusterGroup);
    }

    // Spiderfy the markers on click
    markerClusterGroup.on("clusterclick", function (event) {
      event.layer.spiderfy();
    });

    // Remove the marker group from the map when unmounting
    return () => {
      if (mapRef.current !== null) {
        mapRef.current.removeLayer(markerClusterGroup);
      }
    };
  }, [markers]);



  useEffect(() => {
    if (tags.length === 0) return;  // Assurez-vous qu'il y a des tags pour filtrer les labos

    const filteredLabs = labs.filter(lab => {
        const keywords = lab.keywords || [];  // Assurez-vous que keywords est toujours un tableau
        return keywords.some(keyword => 
            tags.some(tag => keyword.toLowerCase().includes(tag.toLowerCase()))
        );
    });

    console.log("Filtered Labs:", filteredLabs);  // Vérifiez ce qui est filtré

    const newMarkers = filteredLabs.map(lab => {
        const marker = L.marker([lab.latitudelabs, lab.longitudelabs], {
            icon: IconL,
            title: lab.acronymelabs,
        }).bindPopup(
            `<h5><a href='${lab.siteweb}' target='_blank'>${lab.nomlabs} (${lab.acronymelabs})</a></h5>` +
            `<p>Contact : <a href='mailto:${lab.maillabs}'>${lab.maillabs}</a></p>` +
            `<p>Domains :</p> <ul>` +
            dd.filter(gg => gg.labs === lab.acronymelabs)
              .map(df => `<li key=${df.labs + df.cisced}>${df.field}</li>`)
              .join("")
            + `</ul>`
        );
        return marker;
    });

    setMarkers(newMarkers);
}, [labs, tags, dd]);  // Ajoutez les dépendances manquantes pour la mise à jour


// Ajoutez un bout de code pour réinitialiser les marqueurs lors de la modification des tags ou des filtres
const resetMarkers = (newMarkers) => {
    setshowMe(false);
    setMarkers(newMarkers);
    setshowuniv(false);
    setCheckeduniv(false);
    console.log("Reset markers:", newMarkers);  // Pour le debug
};

// Assurez-vous de passer `markerselected` à `resetMarkers` correctement
useEffect(() => {
    if (nLabs.length > 0) {
        resetMarkers(markerselected);
    }
}, [nLabs]);  // Déclencheur pour réinitialiser les marqueurs


  const deleteMarkers = () => {
    setMarkers([]);
  };
  // console.log(nLabs);
  var markerselected = nLabs
    .filter(
      (tt) =>
        ((tt.universite === "USMB" || tt.universite === "UPPA") &&
          checked === true) ||
        ((tt.universite === "UVT" || tt.universite === "UniTBv") &&
          checked3 === true) ||
        ((tt.universite === "UBI" || tt.universite === "IPG") &&
          checked4 === true) ||
        ((tt.universite === "UNITO" || tt.universite === "UNIBS") &&
          checked2 === true) ||
        ((tt.universite === "UNIZAR" || tt.universite === "UPNA") &&
          checked1 === true) ||
        checked5 === true
    )
    .map((d) => {
      const marker = L.marker([d.latitudelabs, d.longitudelabs], {
        icon: IconL,
        title: d.acronymelabs,
      }).bindPopup(
        "<h5><a href='" +
          d.siteweb +
          "' target='_blank' rel='noopener noreferrer'>" +
          d.nomlabs +
          " (" +
          d.acronymelabs +
          ")</a></h5>" +
          "<p>Contact : <a href='mailto:" +
          d.maillabs +
          "'>" +
          d.maillabs +
          "</a></p>" +
          "<p>Domains :</p> <ul>" +
          dd
            .filter((gg) => gg.labs === d.acronymelabs)
            .map((df) => {
              return (
                "<li key=" + df.labs + df.cisced + ">" + df.field + "</li>"
              );
            })
            .join("") +
          "</ul>"
      );

      return marker;
    });

  /*---------------------------------------->END (Search retrieval and data filtering)<---------------------------------*/
  return (
    <div className="container-fluid mt-4" style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {message ? (
          <h1>{translate("titlePagData")}</h1>
        ) : (
          <h1>{translate("tiltleFPage")}</h1>
        )}
      </div>

      <div className="row">
        <div className="col-md-3 p-4">
          <h2 className="">{translate("filters")}</h2>
          <p className="">
            {translate("domains")}: ({translate("lang")})
          </p>
          <div className="mb-3">
            <AutoComplete
              data={state.data}
              value={state.value}
              onChange={onChange2}
              placeholder="e.g. Austria"
              style={{ width: "100%" }}
            />
          </div>
          <p className="">
            {translate("Keywords search")}: ({translate("lang")})
          </p>
          <form className="k-form k-my-8">
            <label className="k-label k-mb-3 ">
              <TagsInput
                value={tags}
                onChange={(tags, changed, changedIndexes, e) =>
                  handleTagsChange(tags, e)
                } // Pass the event to handler
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.defaultPrevented) {
                    // Prevent default to avoid any form submission
                    e.preventDefault();
                    resetMarkers(markerselected);
                  }
                }}
                addOnBlur
                addOnPaste
                inputProps={{ placeholder: "Add tags" }}
                removeKeys={[",", ".", "Enter"]}
                tagProps={{
                  className: "react-tagsinput-tag",
                  classNameRemove: "react-tagsinput-remove",
                }}
                renderLayout={(tagComponents, inputComponent) => (
                  <div className="react-tagsinput22" style={{ width: "100%" }}>
                    {tagComponents}
                    {inputComponent}
                  </div>
                )}
                renderTag={({ tag, key, disabled, onRemove }) => (
                  <span
                    key={key}
                    className="react-tagsinput-tag"
                    style={{
                      backgroundColor: "rgb(24, 81, 158)",
                      color: "white",
                      padding: "5%",
                    }}
                  >
                    {tag}
                    {!disabled && (
                      <span
                        className="react-tagsinput-remove"
                        onClick={() => handleDelete(tags.indexOf(tag))}
                        style={{ color: "white", padding: "10%" }}
                      >
                        x
                      </span>
                    )}
                  </span>
                )}
              />
            </label>
          </form>
          <form>
            <div className="">
              <h2 className=""> {translate("countries")}</h2>
              <br />
              <ul class="list-group">
                <li class="list-group-item">
                  <input
                    className=""
                    name="france"
                    id="france"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                  ></input>
                  <label className="p-1" for="france">
                    {" "}
                    {translate("france")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  <input
                    className=""
                    name="espagne"
                    id="espagne"
                    type="checkbox"
                    checked={checked1}
                    onChange={handleChange1}
                  ></input>
                  <label className="p-1" for="espagne">
                    {" "}
                    {translate("spain")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  <input
                    className=""
                    name="italie"
                    id="italy"
                    type="checkbox"
                    checked={checked2}
                    onChange={handleChange2}
                  ></input>
                  <label className="p-1" for="italy">
                    {" "}
                    {translate("italy")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  {" "}
                  <input
                    className="input-countries"
                    name="roumanie"
                    id="romania"
                    type="checkbox"
                    checked={checked3}
                    onChange={handleChange3}
                  ></input>
                  <label className="p-1" for="romania">
                    {" "}
                    {translate("romania")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  <input
                    className=""
                    name="portugal"
                    id="portugal"
                    type="checkbox"
                    checked={checked4}
                    onChange={handleChange4}
                  ></input>{" "}
                  <label className="p-1" for="portugal">
                    {" "}
                    {translate("portugal")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  <input
                    className=""
                    name="ukraine"
                    id="ukraine"
                    type="checkbox"
                    checked={checked6}
                    onChange={handleChange6}
                  ></input>{" "}
                  <label className="p-1" for="ukraine">
                    {" "}
                    {translate("ukraine")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  <input
                    className=""
                    name="swiss"
                    id="swiss"
                    type="checkbox"
                    checked={checked7}
                    onChange={handleChange7}
                  ></input>{" "}
                  <label className="p-1" for="swiss">
                    {" "}
                    {translate("swiss")}{" "}
                  </label>
                </li>
                <li class="list-group-item">
                  <input
                    className=""
                    name="all"
                    id="all"
                    type="checkbox"
                    checked={checked5}
                    onChange={handleChange5}
                  ></input>{" "}
                  <label className="p-1" for="all">
                    {" "}
                    {translate("all")}{" "}
                  </label>
                </li>
              </ul>
            </div>
          </form>
          <div className="container row pt-2 ">
            <li class="list-group-item">
              <input
                className=""
                name="checkuniv"
                id="checkuniv"
                type="checkbox"
                checked={checkeduniv}
                onChange={handleChangeuniv}
              ></input>{" "}
              <label className="p-1" for="checkuniv">
                {" "}
                {translate("show_univ")}{" "}
              </label>
            </li>
          </div>
          <div className="container row pt-2 ">
            <button
              className="btn"
              style={{ backgroundColor: "#18519E", color: "white" }}
              onClick={() => resetMarkers(markerselected)}
            >
              {translate("Filter")}{" "}
            </button>
          </div>
        </div>
        <div className="col-md-9">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "500px" }}
          >
            {/* Carte */}
            <MapContainer
              center={[45.5675, 5.92079]}
              zoom={5}
              scrollWheelZoom={false}
              className=""
              ref={mapRef}
              style={{ height: "500px" }}
            >
              <TileLayer
                attribution='donn&eacute;es &copy; <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>'
                url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
              />
              {showuniv ? (
                <div>
                  {" "}
                  {datas.map((f) => (
                    <Marker
                      icon={IconC}
                      key={f.Acronyme}
                      position={[f.latitude, f.longitude]}
                    >
                      <Popup>
                        {" "}
                        <h5>
                          <a
                            href={f.siteWeb}
                            title={f.Acronyme}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {f.Nom}
                          </a>{" "}
                        </h5>
                      </Popup>
                    </Marker>
                  ))}
                </div>
              ) : null}
            </MapContainer>
          </div>

          {/* Liste des laboratoires */}
          {!showMe ? (
            <div className="mt-4">
              <h2 className="text-center">{translate("titleTab")}</h2>
              <div className="d-flex flex-wrap justify-content-center gap-3">
                {nLabs
                  .filter(
                    (lab) =>
                      ((lab.universite === "USMB" ||
                        lab.universite === "UPPA") &&
                        checked) ||
                      ((lab.universite === "UVT" ||
                        lab.universite === "UniTBv") &&
                        checked3) ||
                      ((lab.universite === "UBI" || lab.universite === "IPG") &&
                        checked4) ||
                      ((lab.universite === "UNITO" ||
                        lab.universite === "UNIBS") &&
                        checked2) ||
                      ((lab.universite === "UNIZAR" ||
                        lab.universite === "UPNA") &&
                        checked1) ||
                      checked5
                  )
                  .map((lab) => (
                    <Card
                      key={lab.id}
                      className="mb-4 card-hover text-center"
                      style={{ width: "18rem", color: "black" }}
                    >
                      <Card.Body>
                        <Card.Title>{lab.nomlabs}</Card.Title>
                        <Card.Text>
                          <br />
                          Acronym: {lab.acronymelabs}
                          <br />
                          University: {lab.universite}
                        </Card.Text>
                        <Button
                          variant="primary"
                          href={lab.siteweb}
                          target="_blank"
                        >
                          Visit Website
                        </Button>
                      </Card.Body>
                    </Card>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default RecupUNIV;
