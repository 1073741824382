import { LOCALES } from "../locales";
export default {
  [LOCALES.SPAIN]: {
    tiltleFPage: "Seleccione los dominios y los países",
    filters: "Filtros",
    domains: "Búsqueda de dominios",
    countries: "Países",
    france: "Francia",
    spain: "España",
    italy: "Italia",
    romania: "Rumanía",
    portugal: "Portugal",
    swiss: "Suiza",
    all: "Todo",
    search: "Buscar en",
    titlePagData: "RESULTADO SOLICITADO",
    titleTab: "Lista de Unidades de Investigación",
    columnLabs: "Siglas de los laboratorios",
    columnDomain: "Dominios",
    nbreSearch: " Número de investigadores",
    adress: "Dirección ",
    contact: "Contacto ",
    webSite: "ver su página web",
    english: "Inglés",
    french: "Francés",
    romanian: "Rumano",
    italian: "Italiano",
    spanish: "Español",
    portuquese: "Portugués",
    lang: "en inglés",
    ukraine: "Ucrania",
    show_univ: "Mostrar todas las universidades",
    Filter: "Filtrar",
  },
};
